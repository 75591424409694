import React from 'react';

import Form, { FormProps } from '@/Components/Input/Form';
import Input, { InputProps } from '@/Components/Input/Input';

type FormInputProps = FormProps & InputProps;

const FormInput: React.FC<FormInputProps> = ({
  className,
  errorText,
  helperText,
  label,
  required,
  ...props
}) => {
  return (
    <Form
      className={className}
      errorText={errorText}
      helperText={helperText}
      label={label}
      required={required}
    >
      <Input {...props} required={required} />
    </Form>
  );
};

export default FormInput;
